
@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');


* {
  font-family: 'Almarai', sans-serif !important;
}


/* Works on Chrome, Edge, and Safari */
[data-theme=purple] *::-webkit-scrollbar {
  width: 8px;
  
}
[data-theme=purple] *::-webkit-scrollbar-track {
  background: var(--scroll-bg);
  background-color: #DBDBEE!important;
  width: 50px!important;
  border-radius: 20px;
  
}
[data-theme=purple] *::-webkit-scrollbar-thumb {
  background-color: var(--scroll-color);
  border-radius: 20px;
  width: 50px!important;
  background-color: #4B49AC!important;
  
}
[data-theme=purple] * {
  scrollbar-width: thin;
  scrollbar-color: var(--scroll-color) var(--scroll-bg);
  
}
[data-theme=purple] .awesomplete .input-with-feedback {
  border: 1px solid var(--border-color);
}
[data-theme=purple] .form-control {
  border: 1px solid var(--border-color);
}
[data-theme=purple] .search-bar .awesomplete input {
  background-color: var(--bg-color)
}
[data-theme=purple] .modal-backdrop{
  background-color: #2c436b !important;
}
[data-theme=purple] .widget .widget-head .widget-title {
  font-size: var(--text-base);
}
[data-theme=purple] .comment-box .comment-input-header, .form-dashboard-section .section-head, .form-section .section-head, .head-title {
  font-size: var(--text-base);
  font-weight: 600;
}
[data-theme=purple] .comment-box .comment-input-container .ql-editor {
  border: 1px solid var(--border-color);
}
[data-theme=purple] .duration-picker .duration-input {
  background-color: var(--bg-color);
  border: 1px solid var(--border-color);
}
[data-theme=purple] .control-label {
  font-weight: 500;
}
[data-theme="dark"] {
  --bg-color: var(--gray-900);
  --disabled-control-bg: var(--gray-900);
  --border-color: #1c2126;
  --margin-lg : 15px;
  --text-bold: 600;
  --scroll-bg: #161a1f;
  --scroll-color: #1c2126;
}

[data-theme="light"] {
  --bg-color: #f2f5fa;
  --text-bold: 600;
  --control-bg : #fff;
  --control-bg-on-gray: #d4dcea;
  --disabled-control-bg: #f2f5fa;
  --heading-color: #333;
  --border-color:#e3e8f1;
  --avatar-frame-bg: #dde2ea;
  --margin-lg: 15px;
  --sidebar-select-color: #d4dcea;
  --awesomplete-hover-bg: #d4dcea;
  --scroll-bg: #eef1f5;
  --scroll-color: #d9dfe6;
}




[data-theme=purple] .awesomplete > ul > li:hover, .awesomplete > ul > li[aria-selected="true"]{
  background-color: #d7e6f1;
  color: black;
}
[data-theme=purple] .awesomplete input[type=text]{
  color: #000000;
}

[data-theme=purple] [data-label="Create Workspace"]{
  background-color: #ffa00a;
  color: #fff;
}


/* Global CSS */
/*     var(--hover-font-color)  */
/* Colours */
[data-theme=purple] {
  --body-bg-color:#F5F7FF;
  --primary-color: #4B49AC;
  --secondary-color: #7574ff;
  --white-color: #ffffff;
  --black-color: #000000;
  --gray-color: #888;
  --page-head-height: 50px;
  --navbar-height: 50px;
  /* default button color like action above decument create or list view or add in table  */
  --btn-default-class:#4B49AC;
  --btn-default-color-hover-class:white;
  /* primary like save or submit button color like action above decument create or list view */
  --btn-primary-bg-color:#248AFD;
  --btn-primary-bg-color-hover:#50A1FD;
  
  /* navbar colors  */
  --navbar-first-color:white;
  --navbar-second-color:white;

  --non-color-class:#ffffff00;
  /* title color for header text  */
  --title-color:#504B8E;
  /* title color for side bar text  */
  --sidebar-title:#4B49AC;
  --sidebar-icon-color:#4B49AC;
  --sidebar-icon-selected-color:#ffffff;
  --sidebar-font-selected-color:#fff;

  --widget-title-color:#4B49AC;
  --hover-bg-color:#4b49ac9e;
  --hover-font-color:white;
  --header-bg-color:#4b49acc2;
  --card-pill-bg-color:#8d8be370;
  --card-pill-bg-hover-color:#F4F5F6;
  --header-for-create-doc-color:#7c7ae7;
  --header-for-create-doc-font-color:#fff;
  --input-read-only-color:#a2a0ed3d;
  --input-field-write:#7270e4;
  --input-field-write-font-color:#333c44f7;
  --form-control:#f2f2f2;
  --form-control-write:rgba(255, 160, 10, 0.14);
  --form-grid-color:#bbbbbb;
  --grid-heading-row-color:#a2a0ed3d;
  --row-col-color:#d1d8dd;
  --grid-row-hover-color:rgba(255, 152, 0, 0.15);
  --datatable-color:#f4f4f4;
  --btn-shadow-box:#7252e342;
  --btn-create-doc:#7252e342;
  --btn-primary-shadow-box:#6ab4ed96;
  --text-editor-bg-color:#a2a0ed3d;
  --counter-color:#504b8e;
  --button-secondary-dark-color:#504b8e;
  --navbar-text-color:#504b8e; 
  --avatar-bg-color:#a8a7e99e;
  --avatar-font-color:#4947a9;
  

}

[data-theme=purple] .page-container{
  background-color: var(--body-bg-color)!important;
}

[data-theme=purple] .btn-primary:active,
[data-theme=purple] .btn-secondary:focus,
[data-theme=purple] .btn-secondary:active,
[data-theme=purple] .btn-secondary:focus{
  box-shadow: none !important;
}
[data-theme=purple] .btn-primary{
  color: var(--hover-font-color) !important;
  background-color: var(--btn-primary-bg-color)!important;
  border-radius: 5px !important;
  box-shadow: 0px 0px 0px 1px var(--btn-primary-shadow-box)!important;


}
[data-theme=purple] .btn-primary:hover{
  background-color: var(--btn-primary-bg-color-hover) !important;
  color:var(--btn-default-color-hover-class)!important
}



[data-theme=purple] .btn-secondary{
  color: var(--btn-default-class) !important;
  background-color: var(--non-color-class)!important;
  border:1px solid var(--btn-default-class)!important;
  border-radius: 5px !important;
}

[data-theme=purple] .btn-secondary:hover{
  background-color: var(--btn-default-class) !important;
  color:var(--btn-default-color-hover-class)!important;
}

[data-theme=purple] .btn-default{
  color: var(--btn-default-class) !important;
  background-color: white!important;
  border:1px solid var(--btn-default-class)!important;
  border-radius: 5px !important;
  box-shadow: 0px 0px 0px 1px var(--btn-shadow-box)!important;
}
[data-theme=purple] .btn-default:hover{
  background-color: var(--btn-default-class) !important;
  color:var(--btn-default-color-hover-class)!important;
}


[data-theme=purple] .page-head,
[data-theme=purple] .page-head .page-head-content{
  height: var(--page-head-height);
}
[data-theme=purple] .page-head,
[data-theme=purple] .page-head .page-head-content{
  top: var(--page-head-height);
}



/* Top Header */
[data-theme=purple] .navbar{
  background:linear-gradient(to right ,var(--navbar-first-color),var(--navbar-second-color)) !important;
  height: var(--navbar-height);
}
[data-theme=purple] .navbar a{
  color:var(--navbar-text-color)!important;
}
[data-theme=purple] .avatar .standard-image {
  background-color:var(--avatar-bg-color)!important;
  color: var(--avatar-font-color)!important;
}

[data-theme=purple] .navbar-expand{
  background-color: var(--background-color);
}
[data-theme=purple] #navbar-breadcrumbs > li > a::before{
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  margin-left: .3em;
  display: inline-block;

  font-size: 24px;
  transition: 0.2s;
  position: relative;
  top: 3px;
  content: "\f105";
  margin-left: 10px;
  /* color: var(--white-color); */
}

[data-theme=purple] .nav-link.notifications-icon.text-muted:hover{
  fill: var(--non-color-class)!important;
  stroke: var(--hover-font-color)!important;
  --icon-stroke: var(--sidebar-icon-selected-color)!important;
  background-color: var(--hover-bg-color);
  border-radius: 50%;
}

[data-theme=purple] #navbar-breadcrumbs a{
  color: var(--white-color);
  text-decoration: none;
}
[data-theme=purple] header .form-control{
  color: var(--white-color);
}
[data-theme=purple] header .form-control:focus{
  border: 0;
}
[data-theme=purple] .search-bar .awesomplete input{
  height: 32px;
}

[data-theme=purple] header ul#navbar-breadcrumbs{
  margin-top: -8px;
}
[data-theme=purple] header .form-control:focus{
  background-color: var(--white-color) !important;
}



/* Sidebar */
/* header in sidebar  */

[data-theme=purple] .flex .title-text{
  color: var(--title-color);
}
[data-theme=purple] .page-title .sidebar-toggle-btn  {
  --icon-stroke: var(--sidebar-title);
}
/* element in sidebar */
/* element when selected */
[data-theme=purple] .standard-sidebar-item use{
  --icon-stroke: var(--sidebar-icon-color);

}
[data-theme=purple] .standard-sidebar-item.selected{
  background:var(--sidebar-icon-color) !important;
}
[data-theme=purple] .standard-sidebar-item.selected use{
  --icon-stroke: var(--sidebar-icon-selected-color);

}
[data-theme=purple] .standard-sidebar-item.selected a{
  color:var(--sidebar-font-selected-color);
}
[data-theme=purple] .standard-sidebar-item:hover{
  background:var(--hover-bg-color) !important;
}
[data-theme=purple] .standard-sidebar-item:hover use{
  --icon-stroke: var(--sidebar-icon-selected-color);

}
[data-theme=purple] .standard-sidebar-item:hover a{
  color:var(--sidebar-font-selected-color);
}
[data-theme=purple] .layout-side-section .sidebar-label{
  color: var(--title-color);
}
[data-theme=purple] .sidebar-label svg,
[data-theme=purple] .layout-side-section .sidebar-label .icon{
  stroke: var(--primary-color);
}
[data-theme=purple] .list-sidebar-button{
  background-color: var(--non-color-class) !important;
  box-shadow: none !important;
}
[data-theme=purple] .list-sidebar-button:active,
[data-theme=purple] .list-sidebar-button:focus{
  text-decoration: none !important;
  box-shadow: none !important;
}

/* Main Section Layout */
/* wedgit link (card content) */
[data-theme=purple] .onboarding-steps-wrapper a:hover{
  background-color: var(--hover-bg-color)!important;
  color: var(--hover-font-color)!important;
}
[data-theme=purple] .onboarding-steps-wrapper a.active{
  background-color: var(--sidebar-icon-color)!important;
  color: var(--hover-font-color)!important;
}
[data-theme=purple] .widget-body .link-item:hover{
  color: var(--sidebar-font-selected-color);
  background: var(--hover-bg-color);
  
}
[data-theme=purple] .indicator-pill.gray,
[data-theme=purple] .indicator-pill-right.gray,
[data-theme=purple] .indicator-pill-round.gray {
  background: var(--card-pill-bg-color);
  color: var(--title-color);
}

[data-theme=purple] .indicator-pill.gray::before{
  background:var(--title-color)
}
[data-theme=purple] .indicator-pill.yellow{
  background: var(--card-pill-bg-color) !important;
  color: 0f7ad5!important;
  --icon-stroke: var(--white-color)!important;
}
[data-theme=purple] .indicator-pill.yellow::before{
  background:var(--title-color)
}
[data-theme=purple] .widget-body .link-item:hover .indicator-pill.no-margin.yellow{
  background: var(--card-pill-bg-hover-color) !important;
  color: var(--sidebar-font-selected-color)!important;
  fill:var(--sidebar-font-selected-color)!important;
  stroke: var(--sidebar-font-selected-color)!important;
  --icon-stroke: var(--sidebar-font-selected-color)!important;
}

/* main body for section  */

[data-theme=purple] .layout-main-section-wrapper .layout-main-section{
  border:1px solid var(--hover-bg-color);
  border-radius: 10px;
}
[data-theme=purple] .ce-block__content .links-widget-box .widget-head .widget-label .widget-title{
  color: var(--sidebar-icon-color);
}
[data-theme=purple] .ce-block__content .links-widget-box .widget-head .widget-label .widget-title use{
  --icon-stroke: var(--sidebar-icon-color);
}



[data-theme=purple] .widget.shortcut-widget-box:hover span {
  color :var(--sidebar-title)!important;

}
[data-theme=purple] .widget.shortcut-widget-box:hover use {
  --icon-stroke :var(--sidebar-title)!important;
  stroke: var(--sidebar-title)!important;

}

[data-theme=purple] .h4{
  color: var(--title-color);
}


/* list view document page */
/* header action bar */



/* header list document section action bar and filter  */
[data-theme=purple] .input-xs {
  height: 24px!important;
  padding:5px!important
}
[data-theme=purple] .page-form.flex {
  background-color: var(--header-bg-color);
}
[data-theme=purple] .btn-new-doc {
  background-color: var(--header-bg-color)!important;
  box-shadow:  0px 0px 0px 1px  var(--btn-create-doc)!important;
}
[data-theme=purple] .page-actions use {
  --icon-stroke:var(--title-color)!important;
  
}
[data-theme=purple] .page-actions button:hover use{
  --icon-stroke:var(--sidebar-icon-selected-color)!important;

}
[data-theme=purple] .filter-section use {
  --icon-stroke:var(--title-color)!important;
  
}

[data-theme=purple] .filter-section button:hover use{
  --icon-stroke:white!important;

}





/* create new document */

[data-theme=purple] .section-head {
  color:var(--title-color)!important;
}
[data-theme=purple] .form-page .form-tabs-list {
  background-color: var(--header-for-create-doc-color)!important;
  color:var(--hover-font-color)!important;

}
[data-theme=purple] .form-page .form-tabs-list a {
  color:var(--header-for-create-doc-font-color)!important;
}
[data-theme=purple] .grid-heading-row{
  border-bottom:none!important;
  background-color:var(--grid-heading-row-color)!important ;
}
[data-theme=purple] .input-with-feedback.form-control{
  background-color: var(--card-pill-bg-color)!important;
  color: var(--input-field-write-font-color)!important;
}
[data-theme=purple] .form-group .control-input-wrapper .control-input .input-with-feedback.form-control::-moz-placeholder{
  color: white!important;
}
[data-theme=purple] .form-group .control-input-wrapper .control-input .input-with-feedback.form-control::placeholder{
  color: white!important;
}
[data-theme=purple] .like-disabled-input{
  background-color: var(--input-read-only-color)!important;
}

[data-theme=purple] .standard-filter-section select{
  background-color: var(--title-color)!important;
  
}

[data-theme=purple] .input-with-feedback.form-control:focus{
  background-color: var(--card-pill-bg-color)!important;

  border:1px solid var(--input-field-write)!important ;
  /* border-radius: 10px; */
  color: var(--title-color);
  
}


/* Input Fields */

[data-theme=purple] .form-control{
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  background-color: #f2f2f2 !important;
}
[data-theme=purple] .form-control:focus{
  outline: 0;
  border: 1px solid var(--primary-color);
  box-shadow: none;
  background: var(--form-control-write) !important;
}
[data-theme=purple] .navbar .navbar-nav a.nav-link{
  /* color: var(--white-color); */
}
[data-theme=purple] span.notifications-seen use,
[data-theme=purple] symbol#icon-small-down{
  fill: var(--non-color-class)!important;
  stroke: var(--hover-font-color)!important;
  --icon-stroke: var(--sidebar-icon-color)!important;


}


/* Table */

[data-theme=purple] .form-grid{
  border-radius: 0;
  border: 1px solid var(--form-grid-color);
}
[data-theme=purple] .form-grid .grid-heading-row{
  background-color: var(--grid-heading-row-color);
}
[data-theme=purple] .grid-heading-row{
  border-bottom: 1px solid var(--primary-color);
}
[data-theme=purple] .grid-row > .row .col{
  border-left: 1px solid var(--row-col-color);
}
/* [data-theme=purple] .form-grid .grid-row:hover{
  background: var(--grid-row-hover-color);
} */

/* Heading Color */
[data-theme=purple] .head-title, .form-section .section-head, .form-dashboard-section .section-head, .comment-box .comment-input-header{
  color: var(--primary-color);
}

/* Lists */
[data-theme=purple] .list-subject a{
  text-decoration: none;
  transition: 0.3s;
}
[data-theme=purple] .list-subject a:hover{
  color: var(--secondary-color);
}


/* Report Table */
[data-theme=purple] .datatable .dt-header .dt-cell--header .dt-cell__content{
  background-color: var(--datatable-color);
}

[data-theme=purple] .btn{
  box-shadow: 0px 0px 0px 1px var(--btn-shadow-box);
  outline:none !important;

}
[data-theme=purple] .btn.btn-sm.filter-button.btn-primary-light{
  color: var(--btn-default-class) !important;
  background-color: white!important;
  border:1px solid var(--btn-default-class)!important;
  border-radius: 5px !important;
  box-shadow: 0px 0px 0px 1px var(--btn-shadow-box)!important;
}
[data-theme=purple] .btn.btn-sm.filter-button.btn-primary-light:hover{
  background-color: var(--btn-default-class) !important;
  color:var(--btn-default-color-hover-class)!important;
}
[data-theme=purple] .sidebar-action.show-tags a{
  color: var(--btn-default-class) !important;
}
[data-theme=purple] .add-list-group-by.sidebar-action a{
  color: var(--btn-default-class) !important;
}
[data-theme=purple] .filter-button.btn-primary-light{
  outline:none !important;
}
[data-theme=purple] .standard-filter-section .input-with-feedback.form-control.input-xs{
  background-color: white !important;
}

[data-theme=purple] .grid-row .data-row.row.editable-row input:not([type="checkbox"]){
background-color: var(--input-read-only-color)!important;
}
[data-theme=purple] .ql-editor{
background-color: var(--text-editor-bg-color)!important;
}
[data-theme=purple] .document-link-badge{
background-color: var(--text-editor-bg-color)!important;
}
[data-theme=purple] .document-link use {
  --icon-stroke: var(--sidebar-icon-color)!important;
  stroke: var(--sidebar-icon-color)!important;
}
[data-theme=purple] .document-link button:hover use{
  --icon-stroke: var(--sidebar-icon-selected-color)!important;
  stroke: var(--sidebar-icon-selected-color)!important;
}
[data-theme=purple] .count{
  background-color: var(--counter-color)!important;
}

[data-theme=purple] .btn-secondary-dark{
  background-color: var(--button-secondary-dark-color)!important;
}
[data-theme=purple] .btn.btn-xs.btn-secondary-dark.action-btn:active{
  border:1px solid var(--button-secondary-dark-color)!important;
  outline:none !important;
  box-shadow: none!important;
}

[data-theme=purple] .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm  use{
  --icon-stroke: var(--sidebar-icon-color)!important;
  stroke: var(--sidebar-icon-color)!important;
}
[data-theme=purple] .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm  .data-pill {
 background-color: var(--btn-default-class)!important;
 color: white;
}   
[data-theme=purple] .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm  .data-pill:active {
  border:2px solid var(--btn-default-class)!important;
  outline:none !important;
  box-shadow: none!important;
}   
[data-theme=purple] .form-sidebar.overlay-sidebar.hidden-xs.hidden-sm .data-pill use{
  --icon-stroke: var(--sidebar-icon-selected-color)!important;
  stroke: var(--sidebar-icon-selected-color)!important;
}
[data-theme=purple] .text-muted.btn.btn-default.icon-btn:hover use{
  --icon-stroke: var(--sidebar-icon-selected-color)!important;
  stroke: var(--sidebar-icon-selected-color)!important;
}
[data-theme=purple] .widget-body .link-item:hover .indicator-pill.no-margin.yellow{
  background: var(--card-pill-bg-hover-color) !important;
  color: var(--sidebar-font-selected-color)!important;
  fill:var(--sidebar-font-selected-color)!important;
  stroke: var(--sidebar-font-selected-color)!important;
  --icon-stroke: var(--sidebar-font-selected-color)!important;
}

[data-theme=purple] .widget-head .widget-control .indicator-pill.green{
  background: var(--card-pill-bg-color)!important;
  color: var(--title-color)!important;
}
[data-theme=purple] .widget-head .widget-control .indicator-pill.green::before{
  background: var(--title-color)!important;
}

[data-theme=purple] .widget-head .widget-control .indicator-pill.grey{
  background: var(--card-pill-bg-color)!important;
  color: var(--title-color)!important;
}
[data-theme=purple] .widget-head .widget-control .indicator-pill.grey::before{
  background: var(--title-color)!important;
}
[data-theme=purple] .filter-chart.btn.btn-xs.pull-right{
  background: white!important;
  border: 1px solid var(--btn-default-class)!important ;
}
[data-theme=purple] .filter-chart.btn.btn-xs.pull-right use{ 
  --icon-stroke: var(--sidebar-icon-color)!important;
  stroke: var(--sidebar-icon-color)!important;
}


[data-theme=purple] .filter-chart.btn.btn-xs.pull-right:hover{
  background: var(--btn-default-class)!important;
}
[data-theme=purple] .filter-chart.btn.btn-xs.pull-right:hover use{
  --icon-stroke: var(--sidebar-icon-selected-color)!important;
  stroke: var(--sidebar-icon-selected-color)!important;
}
[data-theme=purple] .chart-actions.dropdown.pull-right .btn.btn-xs.btn-secondary.chart-menu use{
  --icon-stroke: var(--sidebar-icon-color)!important;
  stroke: var(--sidebar-icon-color)!important;
}
[data-theme=purple] .chart-actions.dropdown.pull-right .btn.btn-xs.btn-secondary.chart-menu:hover use{
  --icon-stroke: var(--sidebar-icon-selected-color)!important;
  stroke: var(--sidebar-icon-selected-color)!important;
}

[data-theme=purple] .page-form .label-area{
  color:white!important;
}

























































/* [data-theme=purple] {
    --gray-50: #E8F5FE;
    --gray-100: #D0E7FA;
    --gray-200: #A1D0F7;
    --gray-300: #6DB9F1;
    --gray-400: #4091E0;
    --gray-500: #2567B5;
    --gray-600: #1C4F8D;
    --gray-700: #173F6E;
    --gray-800: #112F4F;
    --gray-900: #0B1E30;
    --text-muted: var(--gray-400);
    --text-light: var(--gray-300);
    --text-color: var(--gray-50);
    --heading-color: var(--gray-50);
    --btn-default-bg: var(--gray-700);
    --btn-default-hover-bg: var(--gray-500);
    --bg-blue: var(--blue-600);
    --bg-light-blue: var(--blue-400);
    --bg-dark-blue: var(--blue-800);
    --bg-green: var(--green-600);
    --bg-yellow: var(--yellow-500);
    --bg-orange: var(--orange-500);
    --bg-red: var(--red-600);
    --bg-gray: var(--gray-400);
    --bg-grey: var(--gray-400);
    --bg-darkgrey: var(--gray-700);
    --bg-dark-gray: var(--gray-700);
    --bg-light-gray: var(--gray-800);
    --bg-purple: var(--purple-700);
    --bg-pink: var(--pink-700);
    --bg-cyan: var(--cyan-800);
    --text-on-blue: var(--blue-50);
    --text-on-light-blue: var(--blue-50);
    --text-on-dark-blue: var(--blue-300);
    --text-on-green: var(--dark-green-50);
    --text-on-yellow: var(--yellow-50);
    --text-on-orange: var(--orange-100);
    --text-on-red: var(--red-50);
    --text-on-gray: var(--gray-50);
    --text-on-grey: var(--gray-50);
    --text-on-darkgrey: var(--gray-200);
    --text-on-dark-gray: var(--gray-200);
    --text-on-light-gray: var(--gray-100);
    --text-on-purple: var(--purple-100);
    --text-on-pink: var(--pink-100);
    --text-on-cyan: var(--cyan-100);
    --bg-color: var(--gray-900);
    --fg-color: var(--gray-800);
    --navbar-bg: var(--gray-800);
    --fg-hover-color: var(--gray-700);
    --card-bg: var(--gray-800);
    --disabled-text-color: var(--gray-400);
    --disabled-control-bg: var(--gray-700);
    --control-bg: var(--gray-700);
    --control-bg-on-gray: var(--gray-800);
    --awesomebar-focus-bg: var(--control-bg);
    --awesomplete-hover-bg: var(--gray-700);
    --modal-bg: var(--gray-700);
    --toast-bg: var(--modal-bg);
    --popover-bg: var(--bg-color);
    --error-bg: var(--red-70);
    --error-border: var(--red-400);
    --icon-fill: transparent;
    --icon-stroke: var(--gray-300);
    --alert-text-danger: var(--red-300);
    --alert-text-warning: var(--yellow-300);
    --alert-text-info: var(--blue-300);
    --alert-text-success: var(--green-300);
    --alert-bg-danger: var(--red-900);
    --alert-bg-warning: var(--yellow-900);
    --alert-bg-info: var(--blue-900);
    --alert-bg-success: var(--green-900);
    --sidebar-select-color: var(--gray-800);
    --scrollbar-thumb-color: var(--gray-600);
    --scrollbar-track-color: var(--gray-700);
    --shadow-inset: var(--fg-color);
    --border-color: var(--gray-700);
    --dark-border-color: var(--gray-600);
    --table-border-color: var(--gray-600);
    --highlight-color: var(--gray-700);
    --yellow-highlight-color: var(--yellow-700);
    --btn-group-border-color: var(--gray-800);
    --highlight-shadow: 1px 1px 10px var(--blue-900), 0px 0px 4px var(--blue-500);
    --shadow-base: 0px 4px 8px rgba(114, 176, 233, 0.06), 0px 0px 4px rgba(112, 172, 228, 0.12);
    --diff-added: var(--green-800);
    --diff-removed: var(--red-800);
    --input-disabled-bg: none;
    --checkbox-focus-shadow: 0 0 0 2px var(--gray-600);
    color-scheme: dark;
  }
  [data-theme=purple] .chart-container {
    --charts-label-color: var(--gray-300);
    --charts-axis-line-color: var(--gray-500);
    --charts-stroke-width: 5px;
    --charts-dataset-circle-stroke: #ffffff;
    --charts-dataset-circle-stroke-width: var(--charts-stroke-width);
    --charts-tooltip-title: var(--charts-label-color);
    --charts-tooltip-label: var(--charts-label-color);
    --charts-tooltip-value: white;
    --charts-tooltip-bg: var(--gray-900);
    --charts-legend-label: var(--charts-label-color);
  }
  [data-theme=purple] .heatmap-chart g > rect[fill="#ebedf0"] {
    fill: var(--gray-700);
  }
  [data-theme=purple] .rating {
    --star-fill: var(--gray-500);
  }
  [data-theme=purple] .rating .star-hover {
    --star-fill: var(--gray-400);
  }
  [data-theme=purple] .skeleton {
    --skeleton-bg: var(--gray-800);
  }
  [data-theme=purple] ::-moz-selection {
    color: var(--text-color);
    background: var(--gray-500);
  }
  [data-theme=purple] ::selection {
    color: var(--text-color);
    background: var(--gray-500);
  }
  [data-theme=purple] .indicator {
    --indicator-dot-blue: var(--bg-blue);
  }
  [data-theme=purple] .indicator {
    --indicator-dot-light-blue: var(--bg-light-blue);
  }
  [data-theme=purple] .indicator {
    --indicator-dot-dark-blue: var(--bg-dark-blue);
  }
  [data-theme=purple] .indicator {
    --indicator-dot-green: var(--bg-green);
  }
  [data-theme=purple] .indicator {
    --indicator-dot-yellow: var(--bg-yellow);
  }
  [data-theme=purple] .indicator {
    --indicator-dot-orange: var(--bg-orange);
  }
  [data-theme=purple] .indicator {
    --indicator-dot-red: var(--bg-red);
  }
  [data-theme=purple] .indicator {
    --indicator-dot-gray: var(--bg-gray);
  }
  [data-theme=purple] .indicator {
    --indicator-dot-grey: var(--bg-grey);
  }
  [data-theme=purple] .indicator {
    --indicator-dot-darkgrey: var(--bg-darkgrey);
  }
  [data-theme=purple] .indicator {
    --indicator-dot-purple: var(--bg-purple);
  }
  [data-theme=purple] .indicator {
    --indicator-dot-pink: var(--bg-pink);
  }
  [data-theme=purple] .indicator {
    --indicator-dot-cyan: var(--bg-cyan);
  } */
